
import { defineComponent } from '@nuxtjs/composition-api'
import Success from './Success'
import Form from 'vform'

export default defineComponent({
    props: ['message_type'],
    components: { Success },
    async mounted() {
        this.options = await this.$axios.$get('/api/messages/interested-in')
        try {
            await this.$recaptcha.init()
        } catch (e) {
            console.error(e)
        }
    },
    data() {
        return {
            form: new Form({
                full_name: null,
                email: null,
                country: null,
                message: null,
                message_type: this.message_type,
                recaptcha_response: null,
            }),
            options: [],
            success_message: null,
            error_message: null,
        }
    },
    methods: {
        handleSubmit: async function (ctx) {
            try {
                const token = await this.$recaptcha.execute('login')
                this.form.recaptcha_response = token
            } catch (error) {
                console.log('Login error:', error)
            }

            this.success_message = null
            this.form.post('/api/messages', {}).then(({ data }) => {
                this.$nuxt.$options.router.push('/email/thank-you')
            })
        },
    },
})
