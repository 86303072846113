
import { defineComponent } from '@nuxtjs/composition-api'
import ContactForm from '../components/ContactForm/ContactForm'

export default defineComponent({
    components: { ContactForm },
    auth: false,
    head: {
        meta: [
            {
                hid: 'description',
                name: 'description',
                content:
                    'Convenient and gamified system for surgical education and training for attendees, learners from all stages of training and expertise.',
            },
        ],
    },
})
