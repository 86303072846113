export default {
  __name: 'Error',
  props: ['error'],
  setup(__props) {



return { __sfc: true, }
}

}