export default {
  __name: 'Group',
  props: {
    label: String,
    labelFor: String,
    labelClass: String,
    error: String,
    noBottomMargin: Boolean,
},
  setup(__props) {



return { __sfc: true, }
}

}