export default {
  __name: 'Success',
  props: ['message'],
  setup(__props) {



return { __sfc: true, }
}

}